<template>
  <v-app-bar app
             clipped-right
             color="white"
             dense
             class="px-10"
             height="70px"
             elevation="1"
             style="z-index: 10">
    <v-toolbar-title>{{ toolbar_title }}</v-toolbar-title>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
    >
      <v-icon> {{ ui.snackbar.icon }}</v-icon>
      {{ ui.snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs"> mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->
    <v-spacer></v-spacer>
    <v-menu transition="scroll-y-transition"
            nudge-bottom="30"
            nudge-left="80"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               v-bind="attrs"
               v-on="on" class="button-top">
          <v-icon v-if="!avatar" class="black--text">mdi-account</v-icon>
          <v-avatar v-if="avatar">
            <v-img :src="avatar" :style="'border: 3px solid ' + color + ' !important;'">
              <v-overlay :value="loading" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-img>
          </v-avatar>
        </v-btn>
      </template>

      <v-list nav dense>
        <v-list-item link @click="goToUser">
          <v-list-item-icon class="mr-2">
            <v-icon color="primary">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle>{{ $t('topbar.navbar.profile') }}</v-list-item-subtitle>

        </v-list-item>

        <v-list-item link @click="logOut">
          <v-list-item-icon class="mr-2">
            <v-icon color="primary">mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle>{{ $t('topbar.navbar.sign_out') }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import _ from "@/tools/lodash";

export default {
  name: "TopBar",
  data() {
    return {
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        },
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1
        }
      },
      userInfo: {},
      loading: false,
      title: 'Dashboard',
      room_name: '',
      room_details : false, // inside room details or not (used to update title of top bar)
      toolbar_title: 'Tableau de bord',
      avatar: null,
      color: null,
    }
  },
  methods: {
    snackbar(type, icon, message, timeout) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
    },
    /**
     * Logout function
     * send back to connection view
     * with school code if exist
     * @returns {Promise<void>}
     */
    async logOut() {
      this.$session.destroy()
      await this.$router.push({name: 'Login'})
    },

    /**
     * Generate file image path for user avatar
     * */
    createLinkUserAvatar() {
      return this.avatar = !_.isEmpty(this.userInfo.avatar) ? process.env.VUE_APP_API_ROHAN_PATH + this.userInfo.avatar : 'https://via.placeholder.com/640x360'
    },
    goToUser() {
      if (this.$route.name !== 'User')
        this.$router.push({name: "User"})
    },

    /**
     * Get user info
     *
     * @returns {Promise<void>}
     */
    async getUserInfos() {
      this.loading = true
      try {
        const response = (await this.axios.post('user/info', {
          auth_token: this.$session.get('token'),
          user_key: this.$session.get('key')
        })).data

        this.userInfo.avatar = response.avatar_code
        this.userInfo.color = response.color_code

        this.createLinkUserAvatar()

        this.color = this.userInfo.color

      } catch (e) {
        if (e.response?.status !== undefined) {
          switch (e.response.status) {

              // Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Une erreur a était détectée pendant la récupération des données de votre compte. Veuillez réessayer.', '4000')
              break

              // Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not exist
            case 404:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Une erreur a était détectée pendant la récupération de vos données..', '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Une erreur c\'est produite lors de la récupération des informations utilisateur.', '4000')

          }
        }
      }finally {
        this.loading = false
      }
    },
    checkTitle(name, type) {
      /**
       * Received the title who match with route to display
       */
      if (this.$route.name === 'Home') {
        this.room_details = false
        this.toolbar_title = this.$t('topbar.title.dashboard')
      }
      if (this.$route.name === 'User') {
        this.room_details = false
        this.toolbar_title = this.$t('topbar.title.user')
      }

      if (this.room_details) {
        let type_room = ''
        if (!this._.isUndefined(type)) {
          switch (type) {
            case 1:
              type_room = this.$t('topbar.room_type.team')
              break
            case 0 :
              type_room = this.$t('topbar.room_type.private')
              break
            case 2 :
              type_room = this.$t('topbar.room_type.public')
              break
            default:
              type_room = ''
          }
        }

        this.toolbar_title = type_room + ' - ' + name
      }
    }
  },
  watch: {
    "$route.name"() {
      this.checkTitle()
    }
  },
  async mounted() {

    await this.getUserInfos()
    this.checkTitle()

    // event when user changes avatar, updates avatar in topbar
    this.$root.$on('avatarChanged', (data) => {
      this.loading = true
      this.userInfo.avatar = data
      this.createLinkUserAvatar()
      this.loading = false
    })

    // event when user changes avatar border color, updates border color in topbar
    this.$root.$on('colorChanged', (data) => {
      this.loading = true
      this.userInfo.color = data
      this.color = data

      this.loading = false
    })

    // event when user changes name of team room, updates title
    this.$root.$on('room-name-changed', (name, type) => {
      this.room_details = true
     this.checkTitle(name, type)
    })

  },
  beforeDestroy() {
    this.$root.$off('routeUpdated')
    this.$root.$off('avatarChanged')
    this.$root.$off('colorChanged')
    this.$root.$off('room-name-changed')

    this.room_details = false
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-toolbar__content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .button-top {
    margin-right: 0px !important;
  }

}
</style>
